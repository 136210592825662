import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';
import InvestorsMain from '../components/investors/main';

class InvestorsView extends Highway.Renderer{
  onEnter() {  
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const investors = new InvestorsMain();
    const globals = new Globals();
  }
  onLeaveCompleted(){

  }
}
 
export default InvestorsView;
  