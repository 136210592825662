import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';
import BlogSocial from '../components/single-blog/social';


class SingleBlog extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const globals = new Globals();
    const blogSocial = new BlogSocial();
  }
  onLeaveCompleted(){

  }
}
 
export default SingleBlog;
 