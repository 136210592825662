import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ScrollSmoother} from "../../util/ScrollSmoother";
// import { VelocityTracker } from "../../util/VelocityTracker.js";
// import InertiaPlugin from "../../util/inertiaPlugin"; 
// import SplitText from "../../util/splitText";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);

class Globals{
	constructor() {
		this.data = {
            btn: document.querySelectorAll('button.default'),
            footerLogo: document.querySelector('.main-footer .logo'),
            imageParallaxContainer: document.querySelectorAll('.mouseMoveContainer'),            
            scrollMoreElements: document.querySelectorAll('.scroll-wrapper .scroll > *'),
            burguer: document.querySelector('.menu-button-container'),
            mobileMenu: document.querySelector('.mobile-menu'),
            goApp: document.querySelector('.mobile-app'),
            cookiesContainer: document.querySelector('.cookies-warning'),
            opened: false
		};
		this.init();

        this.resizeCallback = this.resizeCallback.bind(this);
        this.sameHeight = this.sameHeight.bind(this);
        this.appPopup = this.appPopup.bind(this);
        this.closePopUp = this.closePopUp.bind(this);
        this.getCookie = this.getCookie.bind(this);

        this.closeCookies = this.closeCookies.bind(this);
        
        // this.burguerMenu = this.burguerMenu.bind(this);        
	}
  
	init() {
        ScrollSmoother.create({
            smooth: 1,               // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: true,           // looks for data-speed and data-lag attributes on elements
            smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
        });                
        // document.body.style.overflow = 'auto';  
        window.onresize = this.resizeCallback;
        document.body.style.overflow = 'auto';
        if(this.data.burguer)
            this.burguerMenu();

        if(this.data.btn)
            this.btnAnim();

        if(document.querySelector('.scroll-up'))
            this.scrollUp();

        // if(document.querySelector('.parallax-bg'))
        //     this.parallaxBg();

        if(document.querySelector('.same-height'))
            this.sameHeight();
        
        this.mouseMoveParallax();

        this.search();

        if(this.data.goApp){
            this.appPopup();
            this.data.goApp.querySelector('.close').addEventListener('click', this.closePopUp);
        }
        // if(this.data.cookiesContainer && !this.data.cookiesContainer.classList.contains('open') && document.querySelector('body').classList.contains('page-template-page-blog')){          
        //     // let decodedCookie = this.getCookie('__cba');
        //     // if(decodedCookie == null){
        //         gsap.to(this.data.cookiesContainer, {opacity:1, visibility: 'visible', pointerEvents: 'all', duration: .3});       
        //         this.data.cookiesContainer.classList.add('open')
        //         // let cookieString = "__cba=1";
        //         // document.cookie = cookieString;                
        //     // }            
        //     this.data.cookiesContainer.querySelector('.close').addEventListener('click', this.closeCookies);
        // }
        // else{
        //   this.closeCookies();
        // }        
        
        ScrollTrigger.refresh();
        // if(this.data.scrollMoreElements.length > 0)
        //     this.scrollAnimInit();
	}

    closeCookies(){
      console.log(document.querySelector('.cookies-warning')+'close');
      gsap.to(document.querySelector('.cookies-warning'), {opacity:0, visibility: 'hidden', pointerEvents: 'none', duration: .3});       
      document.querySelector('.cookies-warning').classList.remove('open');
    }

    getCookie(name) {
        var dc = document.cookie;
        var prefix = name + "=";
        var begin = dc.indexOf("; " + prefix);
        if (begin == -1) {
            begin = dc.indexOf(prefix);
            if (begin != 0) return null;
        }
        else
        {
            begin += 2;
            var end = document.cookie.indexOf(";", begin);
            if (end == -1) {
            end = dc.length;
            }
        }
        // because unescape has been deprecated, replaced with decodeURI
        //return unescape(dc.substring(begin + prefix.length, end));
        return decodeURI(dc.substring(begin + prefix.length, end));
    } 

    appPopup(){
        const that = this;
        let decodedCookie = this.getCookie('__ampu');
        if(decodedCookie == null){
            setTimeout(() => {
                let cookieString = "__ampu=1";
                document.cookie = cookieString;

                gsap.to(that.data.goApp, {opacity:1, visibility: 'visible', pointerEvents: 'all'})
                gsap.fromTo(that.data.goApp.querySelector('.container'), {opacity:0, visibility: 'hidden', y: '-50px'}, {opacity:1, visibility:'visible', y: '0px', delay: .5, onComplete:() => {
                    gsap.to(that.data.goApp.querySelector('.close').firstElementChild, {opacity: 1, rotate: 225, ease: "Power4.inOut", duration: .6})
                    gsap.to(that.data.goApp.querySelector('.close').lastElementChild, {opacity: .6, rotate: -225, ease: "Power4.inOut", duration: .6})
                }})                                    
            }, 1500);
        }
        
    }    

    closePopUp(){
        gsap.to([event.target.lastElementChild, event.target.firstElementChild], {opacity: 1, rotate: 0, ease: "Power4.inOut", duration: .6, onComplete: () => {            
            gsap.to(document.querySelector('.mobile-app'), {opacity:0, visibility: 'hidden', pointerEvents: 'none'})            
        }})
    }

    search(){
        const that = this;
        let forms = document.querySelectorAll('.search-bar')        
        forms.forEach(formElement => {            
            if(formElement.classList.contains('desktop') ){            
                formElement.querySelector('form input[type="submit"]').addEventListener('click', event => {
                    event.preventDefault();                    
                    if(event.target.parentElement.parentElement.querySelector('label.search-link').classList.contains('open')){                        
                        event.target.parentElement.parentElement.querySelector('label.search-link').classList.remove('open');                        
                        event.target.parentNode.parentNode.submit();
                    }
                    else{                        
                        event.target.parentElement.parentElement.querySelector('label.search-link').classList.add('open');
                        gsap.to( event.target.parentElement.parentElement.querySelector('label.search'), {width: '215px', visibility: 'visible'})
                    }   
                });            
                      
            }                         
        } );                        
    }

    burguerMenu() {
        let that = this;
        let burguerHandler = () => {
            if(that.data.burguer.classList.contains('open')){
                that.data.burguer.classList.remove('open');
                document.body.style.overflow = 'auto';                
                that.mobileMenuFunc('close');
            }
            else{
                that.data.burguer.classList.add('open');
                document.body.style.overflow = 'hidden';
                gsap.to(window, {duration: .2, scrollTo:0, ease: "power4.inOut"});                
                that.mobileMenuFunc('open');
                document.querySelector('.mobile-menu').classList.add('open');
            }
        }        
        // that.data.burguer.removeEventListener('click', burguerHandler);
        that.data.burguer.addEventListener('click', burguerHandler);
        
    }

    mobileMenuFunc(action) {        
        return new Promise( () => {
            let that = this;
            if(action == 'open'){
                gsap.to(that.data.mobileMenu.querySelector('.first-layer'), {
                    x:'-100%',
                    duration: .5,
                    ease: "power4.inOut"
                })
                gsap.to(that.data.mobileMenu, {
                    x: '0%',
                    delay: .1,
                    duration: .5,
                    ease: "power4.inOut",
                    // onComplete: () => {                        
                    //     that.data.mobileMenu.style.overflow = 'scroll';
                    // }
                })
            }
            else if(action == 'close'){
                // that.data.mobileMenu.style.overflow = 'initial';
                gsap.fromTo(that.data.mobileMenu.querySelector('.first-layer'), {
                    x:'-100%',
                },{
                    x:'0%',
                    duration: .5,
                    delay: .1,
                    ease: "power4.inOut"
                })
                gsap.fromTo(that.data.mobileMenu, {
                    x:'0%',
                },{
                    x: '100%',
                    duration: .5,
                    ease: "power4.inOut",
                    onComplete: () => {
         
                    }

                })
            }
        } )     
    }

    // scrollAnimInit() {
    //     let that = this;                
    //     // gsap.fromTo(that.data.scrollArrow, {y: -13}, {y: 13, duration: 1.5, repeat:-1, ease: "power4"})

    //     gsap.to(that.data.scrollMoreElements, {
    //         opacity: 1,            
    //         stagger: { // wrap advanced options in an object
    //             from: 'start',
    //             // amount: 1.5,
    //             each: .2,              
    //             ease: "linear",            
    //         },
    //         repeat: -1
    //     });
    // }

    resizeCallback() {
        const that = this;
        this.sameHeight;
           
    }

    sameHeight(){
        const that = this;
        let maxHeight = 0;        
        document.querySelectorAll('.same-height').forEach( element => {            
            element.querySelectorAll('.card-wrapper').forEach( childEl => {
                if(childEl.offsetHeight > maxHeight)
                    maxHeight = childEl.offsetHeight
            })

            element.querySelectorAll('.card-wrapper').forEach( childEl => {
                childEl.style.height = maxHeight+'px';
            })
        })              
    }

    mouseMoveParallax() {        
		const that = this;
        that.data.imageParallaxContainer.forEach(element => {
            if(element.querySelector('.mouseMoveEl')){
                gsap.set(element.querySelector('.mouseMoveEl'), {perspective:400});
                element.addEventListener('mousemove', event => {
                    that.parallaxIt(event, element.querySelector('.mouseMoveEl').parentElement, element.querySelector('.mouseMoveEl'), -15);
                })	 
            }            
        });        
	}

    parallaxIt(e, parent, target, movement){        
		const that = this;		                
		let relX = e.pageX - parent.offsetLeft;
		let relY = e.pageY - parent.offsetTop;
	  
		gsap.to(target, 1, {
		  x: (relX - parent.offsetWidth / 2) / parent.offsetWidth * movement,
		  y: (relY - parent.offsetHeight / 2) / parent.offsetHeight * movement,
        //   rotationY: (relX - parent.offsetWidth / 2) / parent.offsetWidth * movement,          
		});
	}    

    btnAnim() {
        const that = this;
        that.data.btn.forEach(btn => {
            btn.addEventListener('mouseenter', event => {
                if(btn.classList.contains('white')){
                    gsap.to(event.target.querySelector('.mask'), {y: '100%', ease: "sine.inOut", duration: .2});
                    gsap.to(event.target.querySelector('a, .load-more, span'), {color: '#fff', duration: .2});
                }
                else{                    
                    gsap.to(event.target.querySelector('.mask'), {y: '100%', ease: "sine.inOut", duration: .2})
                    gsap.to(event.target.querySelector('a, .load-more, span'), {color: '#215383', duration: .2});
                }
                
            })

            btn.addEventListener('mouseleave', event => {
                if(btn.classList.contains('white')){
                    gsap.fromTo(event.target.querySelector('.mask'), {y: '100%'}, {y: '200%', ease: "sine.inOut", duration: .2, onComplete: () => {                                 
                        gsap.set(event.target.querySelector('.mask'), {y: '-100%'});
                    }})
                    gsap.to(event.target.querySelector('a, .load-more, span'), {color: '#215383', duration: .2});
                }
                else{
                    gsap.fromTo(event.target.querySelector('.mask'), {y: '-100%'}, {y: 0, ease: "sine.inOut", duration: .2})
                    gsap.to(event.target.querySelector('a, .load-more, span'), {color: '#fff', duration: .2});
                }
            })
        });
    }
	
    scrollUp() {
        gsap.utils.toArray(".scroll-up").forEach(element => {
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: element,
                    toggleActions: "restart reset restart reset",
                    start: "top 100%",
                    end: "bottom -50%",                    
                }
            });
        
            tl.fromTo(element,
                {
                    y: 100,
                    opacity: 0,
                },
                {
                    y: 0,
                    opacity: 1,
                    duration: 1.2,
                    ease: "expo.out"
                }
            );
        });
    }

    // parallaxBg() {        
    //     gsap.to(".parallax-bg", {
    //         scrollTrigger: {
    //             scrub: true
    //         },
    //         y: (i, target) => ScrollTrigger.maxScroll(window) * target.dataset.speed,
    //         ease: "linear"		
    //     });
    // }

	// kill() {
	// 	super.kill();
	// }
}


export default Globals;