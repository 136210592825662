import Highway from '@dogstudio/highway';
// import { ScrollTrigger } from "gsap/ScrollTrigger";

import Globals from '../components/global/global';

import MainHelp from '../components/help/main-help';

class Help extends Highway.Renderer{  
  onEnter() {  
    
  }
  onLeave(){

  }
  onEnterCompleted(){        
    const globals = new Globals();
    const mainhelp = new MainHelp();
  }
  onLeaveCompleted(){

  }
}
 
export default Help;
 