import Highway from '@dogstudio/highway';
import gsap from 'gsap';

import Globals from '../components/global/global';

class AboutUs extends Highway.Renderer{  
  onEnter() {  
    // document.querySelectorAll('.disc-group').forEach(element => {      
    //   element.setAttribute('data-height', element.querySelector('.files-list').offsetHeight);
    //   gsap.set(element.querySelector('.files-list'), {height: 0})
    //   element.addEventListener('click', () => {
    //     if(element.classList.contains('active')){
    //       gsap.to(element.querySelector('.files-list'), {height: '0px', duration: .3, ease: "power4.inOut"})
    //       element.classList.remove('active')
    //     }
    //     else{
    //       let height = element.getAttribute('data-height');
    //       gsap.to(element.querySelector('.files-list'), {height: height+'px', duration: .3, ease: "power4.inOut"})
    //       element.classList.add('active')
    //     }        
    //   })
    // });
  }
  onLeave(){

  }
  onEnterCompleted(){        
    const globals = new Globals();
  }
  onLeaveCompleted(){

  }
}
 
export default AboutUs;
 