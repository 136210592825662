import Highway from '@dogstudio/highway';
import HomeHero from '../components/home/hero';
import HomeHow from '../components/home/how-it-works';
import HomeInvesting from '../components/home/investing';
import GlobalApp from '../components/global/app';
import Globals from '../components/global/global';


class HomeView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const hero = new HomeHero();
    const investing = new HomeInvesting();
    const app = new GlobalApp();
    const globals = new Globals();
    const how = new HomeHow();
  }
  onLeaveCompleted(){

  }
}
 
export default HomeView;
 