import axios from "axios";

class ContactsForm{
	constructor() {
		this.data = {            
			form: document.querySelector('.contact-form'),
		};
 
		// this.handlerSubmit = this.handlerSubmit.bind(this);

		this.init();
	}
  
	init() {
		if(this.data.form)		
			this.data.form.addEventListener("submit", function(event){handlerSubmit(event)}, false);

		function handlerSubmit(event) {			
			let formData = {};
			event.preventDefault();
			event.target.querySelector(".submit").style.opacity = ".5";
			event.target.querySelector(".submit").style.pointerEvents = "none";
	
			let name = event.target.querySelector('input[name="name"').value;
			let email = event.target.querySelector('input[name="email"').value;
			let message = event.target.querySelector('textarea').value;
			
			axios
				.post(window.location.protocol + "//" + window.location.hostname + "/wp-admin/admin-ajax.php?action=sendContactFrom", {
					name: name,
					email: email,
					message: message
				})
				.then((response) => {
					// setTimeout(() => {
						if(response.data == '1'){
							event.target.querySelector(".submit").style.opacity = "1";
							event.target.querySelector(".submit").style.pointerEvents = "all";
							document.querySelector('.response').style.opacity = '1';
							document.querySelector('.response').innerText = 'Email Sent';
							setTimeout(() => {
								document.querySelector('.response').style.opacity = '0';
							}, 2000);
						}
						else{
							document.querySelector('.response').innerText = 'Error';
							document.querySelector('.response').style.opacity = '1';
							setTimeout(() => {
								document.querySelector('.response').style.opacity = '0';
							}, 2000);
						}					
					// }, 5000);				
				})		
		}
	}

}


export default ContactsForm;