import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import DrawSVGPlugin from "../../util/drawSVG"; 
// import { Observer } from "../../util/observer";
import splitText from '../../util/splitText';
import {ScrollSmoother} from "../../util/ScrollSmoother";
import * as echarts from 'echarts';

import Swiper, { Pagination } from 'swiper';
Swiper.use([Pagination]);
import 'swiper/css';
import 'swiper/css/pagination';

gsap.registerPlugin(DrawSVGPlugin, ScrollTrigger, splitText, ScrollSmoother);

class InvestorsMain{
	constructor() {
		this.data = {
			// 'currentIndex': -1,
            // 'animating': null,        
            'smoother': null,
            'corporateChart': null,
            'retailChart': null,
            'sameHeight': document.querySelectorAll('.same-height-container'),
            'bgParallax': document.querySelectorAll('.bg-parallax'),
            'sliders': document.querySelectorAll('.slider-wrapper'),
            'sliderInstance': [],
            'counterEls': document.querySelectorAll('.counter'),
            'pies': document.querySelectorAll('#pie'),            
		};
		this.init();
	}
  
	init() {
    if(document.querySelector('body').classList.contains('.page-template-page-investors'))
      this.investorsAnimation();
    this.sameHeight(this.data.sameHeight);
    this.backgroundParallax(this.data.bgParallax);
    this.initSlider(this.data.sliders);
    this.initCounters(this.data.counterEls);
    this.initPies(this.data.pies);
	}

    initCounters(obj){
        const that = this;
        obj.forEach((el, i) => {
            let value = JSON.parse(el.querySelector('span.value').getAttribute('data-value'));            
            ScrollTrigger.create({
                trigger: el,
                onEnter: () => {                                        
                    that.counterAnimation(el);
                },
                onEnterBack: () => { 
                    that.counterAnimation(el)
                },
            });
        });
    }

    initSlider(elem){
        const that = this;
        gsap.utils.toArray(elem).forEach((slider, key) => {
            that.data.sliderInstance[key] = new Swiper(slider, {
                pagination: { 
                    el: slider.querySelector('.swiper-pagination'), 
                    type: 'bullets',
                    clickable: true
                },
                speed: 1000,
            });
        });
    }
    initPies(el){
        const that = this;
        gsap.utils.toArray(el).forEach((target, key) => {
            ScrollTrigger.create({
                trigger: target,
                onEnter: () => { 
                    that.pieAnimation(target);
                },
                onEnterBack: () => { 
                    that.pieAnimation(target)
                },
            });        
        });        
    }

    pieAnimation(el){
        const that = this;        
        gsap.fromTo(el.querySelector('circle'), {drawSVG:0}, {drawSVG:'100%', ease: "power4.inOut", duration: 6})
    }

    counterAnimation(obj){                
        let values = obj.querySelector('.value').getAttribute('data-value');
        values = JSON.parse(values);                
        let element = obj.querySelector(".counter .value");        
        let count = 0;
        let maxCout = values.length;
        const step = () => {
            let obj5 = null;
            let value = null;
            let type = null;
            if(obj.classList.contains('float')){
                obj5 = {value:0.00};
                value = parseFloat(values[count].value).toFixed(1)
                type = 'float';
            }            
            else if(obj.classList.contains('int')){
                obj5 = {value:0};
                value = parseInt(values[count].value);                
                type = 'int';
            }            
            let letters = values[count].value.replace(/[^A-Za-z]+/g, '');                  
            gsap.to(obj5, {
                value: value,
                duration: 5 / values.length,
                ease:"none", 
                onUpdate:() => {
                    if (type == 'float'){
                        element.innerHTML = obj5.value.toFixed(1)+letters;
                    }                        
                    else if(type == 'int'){                        
                        element.innerHTML = obj5.value.toFixed(0)+letters;
                    }

                },
                onComplete: () => {                 
                    count ++;                       
                    if(count < maxCout && count > 0){                        
                        window.requestAnimationFrame(step);                           
                    }                                                   
                }
            }); 
        }
        setTimeout(() => {
            window.requestAnimationFrame(step);
        }, 0);        
    }

    backgroundParallax(el){        
        el.forEach((section, i) => {
            gsap.utils.toArray(section.querySelectorAll('img')).forEach((element)=>{
                const heightDiff = element.offsetHeight - element.parentElement.offsetHeight;                
                gsap.fromTo(element,{ 
                y: -heightDiff
                }, {
                scrollTrigger: {
                    trigger: section,
                    scrub: true
                },
                y: 0,
                ease: "none"
                });
            })
            
        });
    }

    sameHeight(el){
        const that = this;
        let maxHeight = 0;

        el.forEach(element => {
            if(element.querySelector('.same-height').offsetHeight > maxHeight){
                maxHeight = element.querySelector('.same-height').offsetHeight;
            }            
        });

        el.forEach(element => {            
            element.querySelector('.same-height').style.height = maxHeight+'px';                        
        });
    }
    investorsAnimation() {      
        const that = this;

        let titles = document.querySelectorAll('.slide h2');
        let titlesArr = gsap.utils.toArray(titles);

        gsap.to(window, {scrollTo:0, duration: .1});
        // document.querySelector('body').style.overflow = 'hidden';
        gsap.fromTo("svg.intro-logo", {scale: 1.5}, {duration: 3, scale: 1});
        gsap.fromTo("svg.intro-logo path", {drawSVG:0}, {duration: 4, drawSVG:"102%", ease: "Power4.inOut", onComplete: () => {
            gsap.to('.investors-load-screen .screen', {y: '-100vh', ease: "Power4.inOut", delay: 0.1, duration: .3})
            gsap.to(document.querySelector('.investors-load-screen .mask'), {y: '-100vh', duration: 0.5, ease:"power4.inOut"});                        
            gsap.to('.investors-load-screen', {autoAlpha:0, duration:.5, pointerEvents: 'none'})            
            document.querySelector('body').style.overflow = 'auto';                        
            gsap.fromTo(titlesArr, {autoAlpha: 0}, {autoAlpha: 1, duration:2})

            // gsap.set(titlesArr, {autoAlpha: 0})
            this.data.smoother = ScrollSmoother.create({
                smooth: 2,               // how long (in seconds) it takes to "catch up" to the native scroll position
                effects: true,           // looks for data-speed and data-lag attributes on elements
                smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
            }); 

            
            
            //TITLES ANIMATION
            if(document.body.offsetWidth > 1024){
                titlesArr.forEach(title => {
                    let splitHeadings = new SplitText(title, { type: "words, chars, lines", linesClass: "clip-text"});
                    splitHeadings.chars.forEach((char, i) => {
                        that.data.smoother.effects(char, { speed: 1, lag: (i + 1) * 0.02 });
                    });
                });            
            }
            
            that.pageAnimations();            
        }});
    }

    pageAnimations() {
        const that = this;
        let imagesCols = document.querySelectorAll('.img-wrapper ul');        
        gsap.fromTo(imagesCols, {autoAlpha:0},{autoAlpha:1, duration: 2.5})

        gsap.utils.toArray("section h3").forEach(function(elem) {
            let splitHeadings = new SplitText(elem, { type: "chars, words", linesClass: "clip-text"}); 
            ScrollTrigger.create({
                trigger: elem,
                onEnter: () => { 
                    that.headingAnimationSecond(splitHeadings, elem, 1);
                },
                onEnterBack: () => { 
                    that.headingAnimationSecond(splitHeadings, elem, -1)
                },
            });
        });

        // ScrollTrigger.create({
        //     trigger: document.querySelector('.corporate-graph'),            
        //     onEnter: () => {                                                
        //         that.corporateGraph(document.querySelector('.corporate-graph'));
        //     },
        //     onEnterBack: () => {                     
        //         that.corporateGraph(document.querySelector('.corporate-graph'));
        //     },
        //     onLeave: () => {
                
        //     }
        // });

        ScrollTrigger.create({
            trigger: document.querySelector('.retail-funds-graph'),
            onEnter: () => {                                
                that.retailGraph(document.querySelector('.retail-funds-graph'));
            },
            onEnterBack: () => {                     
                that.retailGraph(document.querySelector('.retail-funds-graph'));
            },
            onLeave: () => {
                
            }
        });

        gsap.utils.toArray("p").forEach(function(elem) {
            ScrollTrigger.create({
                trigger: elem,
                // start: "top 100%",
                // end: "bottom -50%",
                onEnter: () => {                     
                    that.paragraphAnimation(elem, 1);
                },
                onEnterBack: () => {                      
                    that.paragraphAnimation(elem, -1);
                },
            });
        });

        gsap.utils.toArray(".scroll-up").forEach(function(elem) {            
            ScrollTrigger.create({
                trigger: elem,
                onEnter: () => {                     
                    that.scrollUpAnimation(elem, 1);
                },
                onEnterBack: () => {                     
                    that.scrollUpAnimation(elem, -1);
                },
            });
        });

        // gsap.utils.toArray(".back-splash").forEach(function(elem) {            
        //     ScrollTrigger.create({
        //         trigger: elem,
        //         onEnter: () => {                     
        //             that.backsplahAnimation(elem);
        //         },
        //         onEnterBack: () => {                      
        //             that.backsplahAnimation(elem);
        //         },
        //     });
        // });

        gsap.utils.toArray(".slide svg.border").forEach(function(elem) {
            ScrollTrigger.create({
                trigger: elem,                
                onEnter: () => { 
                    gsap.fromTo(elem.querySelector('rect'), 
                    {
                        drawSVG:0
                    }, 
                    {
                        duration: 2, 
                        drawSVG:"102%", 
                        delay: 2, 
                        ease: "Power4.inOut",                        
                    })                      
                    gsap.fromTo(elem.parentElement, 
                    {
                        autoAlpha:0
                    }, 
                    {
                        autoAlpha: 1,
                        delay: 1.5, 
                        ease: "Power4.inOut",
                    })
                },
                onEnterBack: () => {                     
                    gsap.fromTo(elem.querySelector('rect'), 
                    {
                        drawSVG:'0%'
                    }, 
                    {
                        duration: 2, 
                        drawSVG:'-102%', 
                        delay: 2, 
                        ease: "Power4.Out",                        
                    })     
                    gsap.fromTo(elem.parentElement, 
                        {
                            autoAlpha:0
                        }, 
                        {
                            autoAlpha: 1,
                            delay: 1.5, 
                            ease: "Power4.inOut",                        
                        })     
                },
            });            
        })        

        gsap.utils.toArray(".slide h4").forEach(function(elem) {
            let splitHeadings = new SplitText(elem, { type: "chars, words, lines", linesClass: "clip-text"}); 
            ScrollTrigger.create({
                trigger: elem,
                onEnter: () => { 
                    that.headingAnimationThird(splitHeadings, elem, 1);                
                },
                onEnterBack: () => { 
                    that.headingAnimationThird(splitHeadings, elem, -1)                    
                },
            });
        });
        
        
        // gsap.utils.toArray(".slide h2").forEach(function(elem) {
        //     // that.hide(elem); // assure that the element is hidden when scrolled into view                                

        //     ScrollTrigger.create({
        //         trigger: elem,   
        //         // start: "bottom bottom",
        //         onEnter: function() { that.headingAnimation(elem, 1) },
        //         onEnterBack: function() { that.headingAnimation(elem, -1) },
        //         // onLeave: function() { that.hide(elem) } // assure that the element is hidden when scrolled into view
        //     });
        // });


        // gsap.utils.toArray(".product ul").forEach(function(elem) {
            
        // })
    }

    retailGraph(elem){
        const that = this;
        const res = elem.getAttribute('data-values');
        const data = JSON.parse(res);

        let chartDom = document.querySelector('.retail-funds-graph');
        if(that.data.retailChart){            
            that.data.retailChart.dispose();
        }        
        that.data.retailChart = echarts.init(chartDom);
        let option;
        
        let years = [];
        let cds = [];
        let mmfs = [];
        let fietfs = [];

        data.forEach((element, key) => {
            years[key] = element[0];
            cds[key] = element[1];
            mmfs[key] = element[2];
            fietfs[key] = element[3];
        }); 
        
       
        option = {
            title: {
                // text: 'Total Retail Funds in CDs, MMFs & Fixed Income ETFs ($trillions)'
            },
            legend: {
                data: ['CDs', 'MMFs', 'FI ETFs'],
                bottom: '0'
            },
            toolbox: {                
            },
            tooltip: {},
            xAxis: {
                data: years,
                splitLine: {
                show: false
                }
            },
            yAxis: {},
            series: [
                {
                    name: 'CDs',
                    type: 'bar',
                    data: cds,
                    emphasis: {
                        focus: 'series'
                    },
                    animationDelay: function (idx) {
                        return idx * 100;
                    },       
                    animationEasing: 'quarticInOut',
                    animationDuration: 1000             
                },                
                {
                    name: 'MMFs',
                    type: 'bar',
                    data: mmfs,
                    emphasis: {
                        focus: 'series'
                    },
                    animationDelay: function (idx) {
                        return idx * 200;
                    },
                    animationEasing: 'quarticInOut',
                    animationDuration: 1000   
                },
                {
                    name: 'FI ETFs',
                    type: 'bar',
                    data: fietfs,
                    emphasis: {
                        focus: 'series'
                    },
                    animationDelay: function (idx) {
                        return idx * 300;
                    },
                    animationEasing: 'quarticInOut',
                    animationDuration: 1000   
                },
            ],                        
            animationEasing: 'elasticOut',
            animationDelayUpdate: function (idx) {
                return idx * 5;
            }
        };
        setTimeout(() => {
            option && that.data.retailChart.setOption(option);
        }, 1500);        
    }

    corporateGraph(elem){
        const that = this;
        const res = elem.getAttribute('data-values');
        const data = JSON.parse(res);
        let years = [];
        let values = [];            
        data.forEach((element, key) => {
            years[key] = element[0];
            values[key] = element[1];
        });            
        let chartDom = document.querySelector('.corporate-graph');
        if(that.data.corporateChart){            
            that.data.corporateChart.dispose();
        }            
        that.data.corporateChart = echarts.init(chartDom);
        var option = {
            title: {
                // text: 'Corporate Debt Outstanding ($trillion)',
                // textAlign: 'center',                    
            },
            tooltip: {},
            legend: {
                data: ['Value'],
                bottom: '0',                
            },
            xAxis: {
                data: years
            },
            yAxis: {},
            series: [
                {
                name: '',
                type: 'line',
                data: values,
                animationEasing: 'quarticInOut',
                animationDuration: 2000   
                }
            ]
        };
        setTimeout(() => {
            that.data.corporateChart.setOption(option);
        }, 1500);        
    }

    backsplahAnimation(elem) {
        gsap.fromTo(elem, {
            scale: 0.1,
            autoAlpha:0
        },{scale: 1,
            autoAlpha:1,
            duration: 1.5,  
            delay: .5,
            ease: "elastic.inOut(.5, 0.5)"       
        })     
    }

    scrollUpAnimation(elem, direction){
        gsap.fromTo([elem], {
            yPercent: 20 * direction,
            autoAlpha:0
        },{yPercent: 0,
            autoAlpha:1,
            duration: 1,  
            delay: 1,
            ease: "power4.Out"       
        })     
    }

    paragraphAnimation (elem, direction){
        gsap.fromTo(elem, {
            yPercent: 20 * direction,
            autoAlpha:0
        },{yPercent: 0,
            autoAlpha:1,
            duration: 1,  
            delay: .5,
            ease: "power4.Out"       
        })        
    }

    headingAnimationSecond(splitHeadings, elem, direction) {
        let tl = gsap.timeline({
            defaults: {duration: 1, ease: "power4.Out"},
        });
        
        tl.fromTo(splitHeadings.chars, {autoAlpha: 1, yPercent: 150 * direction}, {
            autoAlpha: 1,
            yPercent: 0,
            duration: 1.2,
            ease: "power4.inOut",
            stagger: {
                each: 0.02,
                from: 0
            }
        }, 0.2);        
    }

    headingAnimationThird(splitHeadings, elem, direction) {
        let tl = gsap.timeline({
            defaults: {duration: 1, ease: "power4.Out"},
        });
        
        tl.fromTo(splitHeadings.chars, {autoAlpha: 0}, {
            autoAlpha: 1,
            
            duration: 1.2,
            ease: "power4.inOut",
            stagger: {
                each: 0.02,
                from: 0
            }
        }, 0.2);        
    }


    // headingAnimation(elem, direction) {
    //     // direction = direction || 1;
      
    //     let tl = gsap.timeline({
    //         defaults: {duration: 1, ease: "power4.inOut"},            
    //     });
        
    //     let splitHeadings = new SplitText(elem, { type: "words,chars", linesClass: "clip-text"});
    //     tl.fromTo(splitHeadings.chars, {autoAlpha: 0, yPercent: 150 * direction}, {
    //         autoAlpha: 1,
    //         yPercent: 0,
    //         duration: 1,
    //         ease: "power4.inOut",
    //         stagger: {
    //             each: 0.02,
    //             from: "random"
    //         }
    //     }, 0.2);        
    // }
    
    // hide(elem) {
    //     gsap.set(elem, {autoAlpha: 0});
    // }
    
    // gotoSection(index, direction) {           
    //     const that = this;
    //     let sections = document.querySelectorAll(".slide");        
    //     let wrap = gsap.utils.wrap(0, sections.length - 1);        
    //     index = wrap(index);
    //     that.data.animating = true;
    //     let fromTop = direction === -1,
    //         dFactor = fromTop ? -1 : 1,
    //         tl = gsap.timeline({
    //             defaults: {duration: 1.25, ease: "power1.inOut"},
    //             onComplete: () => that.data.animating = false,
    //         });
    //     let headings = gsap.utils.toArray(".slide h2");
    //     let splitHeadings = headings.map(heading => new SplitText(heading, { type: "words,chars", linesClass: "clip-text"}));

    //     if (that.data.currentIndex >= 0) {
    //         gsap.set(sections[that.data.currentIndex], { zIndex: 0 });
    //         tl.to(sections[that.data.currentIndex], { autoAlpha: 0, duration: 1 });
    //     }
    //     gsap.set(sections[index], { autoAlpha: 1, zIndex: 1 });        
    //     tl.fromTo(splitHeadings[index].chars, {autoAlpha: 0, yPercent: 150 * dFactor}, {
    //         autoAlpha: 1,
    //         yPercent: 0,
    //         duration: 1,
    //         ease: "power4.inOut",
    //         stagger: {
    //             each: 0.02,
    //             from: "random"
    //         }
    //     }, 0.2);
    //     that.data.currentIndex = index;
    //   }
}


export default InvestorsMain