import Highway from '@dogstudio/highway';
import PostsBlog from '../components/blog/posts';
import Globals from '../components/global/global';


class BlogView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const globals = new Globals();
    const posts = new PostsBlog();
  }
  onLeaveCompleted(){

  }
}
 
export default BlogView;
 