import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
import axios from "axios";
import highway from '@dogstudio/highway';

class PostsBlog{
	constructor() {
		this.data = {
            catBtn: document.querySelectorAll('.cats .cat-wrapper'),
            catMask: document.querySelector('.posts-wrapper .mask'),
            loadMoreBtn: document.querySelector('.posts .load-more')
		};
		this.init();
	}
  
	init() {				
        this.catsBtnAnim();
        this.getPosts();
        this.loadMore();
	}	

    catsBtnAnim() {
        const that = this;     
        that.data.catBtn.forEach(btn => {
            
            btn.addEventListener('mouseenter', event => {
                if(!btn.classList.contains('active')){                    
                    gsap.to(event.target.querySelector('.mask'), {y: '0%', ease: "sine.inOut", duration: .2})
                    gsap.to(event.target.querySelector('h5'), {color: '#fff', duration: .2});
                    gsap.to(event.target.querySelector('.nr'), {color: '#fff', duration: .2});
                }
            })
            btn.addEventListener('mouseleave', event => {                
                if(!btn.classList.contains('active')){                    
                    gsap.fromTo(event.target.querySelector('.mask'), {y: '0%'}, {y: '100%', ease: "sine.inOut", duration: .2, onComplete: () => {
                        gsap.set(event.target.querySelector('.mask'), {y: '-100%'})
                    }})                    
                    gsap.to(event.target.querySelector('h5'), {color: '#09427A', duration: .2});
                    gsap.to(event.target.querySelector('.nr'), {color: '#09427A', duration: .2});
                }
            })
                 
        });
    }

    loadMore() {
        const that = this;
        that.data.loadMoreBtn.addEventListener('click', event => {            
            event.preventDefault();
            event.stopPropagation();
            let cat = document.querySelector('.cats .cat-wrapper.active').dataset.cat;
            let offset = document.querySelector('.posts').dataset.offset;                         
            gsap.to(that.data.catMask, {visibility: 'visible', opacity: 1, duration: .2});
            axios
                .get("/wp-json/blog/v1/loadMore/?cat="+cat+"&offset="+offset)
                .then((response) => {                                                               
                    response.data.forEach(post => {                                
                        document.querySelector('.posts-wrapper-grid').innerHTML += `
                            <div class="post-wrapper">  
                                <a class="zoom-img" href="${post.url}" alt="${post.title}">             
                                    <div>
                                        <img src="${post.image}" alt="${post.title}">                                        
                                    </div>
                                    <h5>${post.title}</h5>
                                </a>
                            </div>
                        `   
                    });     
                    highway.updateLinks();
                    let numberPostsDom = document.querySelectorAll('.post-wrapper').length;
                    if (numberPostsDom >= document.querySelector('.cat-wrapper.active').dataset.count){                        
                        that.data.loadMoreBtn.classList.add('hide');
                    }
                    else{
                        that.data.loadMoreBtn.classList.remove('hide');
                    }
                    document.querySelector('.posts').dataset.offset += 4;
                    gsap.to(that.data.catMask, {visibility: 'hidden', opacity: 0, duration: .2})
                });                                    
        })
    }

    getPosts() {
        // var Emitter = require('tiny-emitter');
        // var emitter = new Emitter();
		const that = this;
        let numberPostsDom = 0;
		that.data.catBtn.forEach(btn => {
			btn.addEventListener('click', event => {
                let newUrl = btn.dataset.url;                
                window.history.pushState({}, '', newUrl);

                gsap.to(window, {duration: .4, scrollTo:"#posts", ease: "power4.inOut"});

                document.querySelector('.posts').dataset.offset = 4;

                gsap.fromTo(document.querySelector('.cat-wrapper.active .mask'), {y: '0%'}, {y: '100%', ease: "sine.inOut", duration: .2, onComplete: () => {
                    gsap.set(document.querySelector('.cat-wrapper.active .mask'), {y: '-100%'})
                }})                                
                gsap.to(document.querySelector('.cat-wrapper.active h5'), {color: '#09427A', duration: .2});  
                gsap.to(document.querySelector('.cat-wrapper.active .nr'), {color: '#09427A', duration: .2});                
                document.querySelector('.cat-wrapper.active').classList.remove('active');
                btn.classList.add('active');

                gsap.to(that.data.catMask, {visibility: 'visible', opacity: 1, duration: .2})
                let cat = btn.dataset.cat;
				axios
					.get("/wp-json/blog/v1/getPosts/?cat="+cat)
					.then((response) => {                                           
                        document.querySelector('.posts-wrapper-grid').innerHTML = '';
                        response.data.forEach(post => {                                
                            document.querySelector('.posts-wrapper-grid').innerHTML += `
                                <div class="post-wrapper">  
                                    <a class="zoom-img" href="${post.url}" title="${post.title}">
                                        <div>
                                            <img src="${post.image}" alt="${post.title}">                                        
                                        </div>
                                        <h5>${post.title}</h5>
                                    </a>
                                </div>
                            `;
                        });                                   
                        highway.updateLinks();                         
                        let numberPostsDom = document.querySelectorAll('.post-wrapper').length;
                        if (numberPostsDom >= document.querySelector('.cat-wrapper.active').dataset.count){                        
                            that.data.loadMoreBtn.classList.add('hide');
                        }
                        else{
                            that.data.loadMoreBtn.classList.remove('hide');
                        }
                        gsap.to(that.data.catMask, {visibility: 'hidden', opacity: 0, duration: .2})
					})		                    
			})
		})        
    }

}
export default PostsBlog;

