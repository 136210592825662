// import Highway from '@dogstudio/highway';
import gsap, { snap } from 'gsap';
import Draggable from "gsap/Draggable";
import InertiaPlugin from "../../util/inertiaPlugin"; 
import drawSVG from "../../util/drawSVG";

// import ApexCharts from 'apexcharts'
// import Splide from '@splidejs/splide';
gsap.registerPlugin(Draggable, InertiaPlugin, drawSVG);

class HomeInvesting{    
	constructor() {
		this.data = {
            sliders: document.querySelectorAll('.calculator .slider-wrapper'),
            knobHandle: document.querySelectorAll('.calculator .slider-wrapper .knob'),
            chartOptions: null,
            chartDom: document.getElementById('chart'),
            char: null
		};
		this.init();
	}
  
	init() {		    
        // this.calculatorSliders();
        // window.addEventListener('resize', this.calculatorSliders)         

        
	}
	
    calculatorSliders () {
        const that = this;
        
        const data = [          
          {
            'Maturity': '5/11/2023',	
            'yas_bond_yld': '4.01%'
          },
          {
            'Maturity': '6/3/2023',	
            'yas_bond_yld': '4.02%'
          },
          {
            'Maturity': '6/12/2023',	
            'yas_bond_yld': '4.03%'
          },
          {
            'Maturity': '6/22/2023',	
            'yas_bond_yld': '4.33%'
          },
          {
            'Maturity': '6/23/2023',	
            'yas_bond_yld': '4.21%'
          },
          {
            'Maturity': '7/7/2023',	
            'yas_bond_yld': '4.40%'
          },
          {
            'Maturity': '7/15/2023',	
            'yas_bond_yld': '4.40%'
          },
          {
            'Maturity': '8/14/2023',	
            'yas_bond_yld': '4.50%'
          },
          {
            'Maturity': '8/15/2023',	
            'yas_bond_yld': '4.77%'
          },
          {
            'Maturity': '9/2/2023',	
            'yas_bond_yld': '4.94%'
          },
          {
            'Maturity': '9/11/2023',	
            'yas_bond_yld': '4.63%'
          },
          {
            'Maturity': '9/14/2023',	
            'yas_bond_yld': '4.43%'
          },
          {
            'Maturity': '9/15/2023',	
            'yas_bond_yld': '4.68%'
          },
          {
            'Maturity': '9/15/2023',	
            'yas_bond_yld': '4.62%'
          },
          {
            'Maturity': '10/26/2023',	
            'yas_bond_yld': '4.64%'
          },
          {
            'Maturity': '1/10/2024',	
            'yas_bond_yld': '4.72%'
          },
          {
            'Maturity': '1/11/2024',	
            'yas_bond_yld': '4.83%'
          },
          {
            'Maturity': '1/19/2024',	
            'yas_bond_yld': '4.82%'
          },
          {
            'Maturity': '2/1/2024',	
            'yas_bond_yld': '4.94%'
          },
          {
            'Maturity': '2/5/2024',	
            'yas_bond_yld': '4.74%'
          },
          {
            'Maturity': '2/11/2024',	
            'yas_bond_yld': '4.73%'
          },
          {
            'Maturity': '2/26/2024',	
            'yas_bond_yld': '4.55%'
          },
          {
            'Maturity': '3/7/2024',	
            'yas_bond_yld': '4.81%'
          },
          {
            'Maturity': '3/8/2024',	
            'yas_bond_yld': '4.67%'
          },
          {
            'Maturity': '3/8/2024',	
            'yas_bond_yld': '4.81%'
          },
          {
            'Maturity': '3/11/2024',	
            'yas_bond_yld': '4.66%'
          },
          {
            'Maturity': '3/13/2024',	
            'yas_bond_yld': '4.95%'
          },
          {
            'Maturity': '3/15/2024',	
            'yas_bond_yld': '4.90%'
          },
          {
            'Maturity': '3/18/2024',	
            'yas_bond_yld': '4.65%'
          },
          {
            'Maturity': '3/22/2024',	
            'yas_bond_yld': '4.74%'
          },
          {
            'Maturity': '3/28/2024',	
            'yas_bond_yld': '5.00%'
          },
          {
            'Maturity': '4/1/2024',	
            'yas_bond_yld': '4.64%'
          },
          {
            'Maturity': '4/2/2024',	
            'yas_bond_yld': '4.78%'
          },
          {
            'Maturity': '4/10/2024',	
            'yas_bond_yld': '4.50%'
          },
          {
            'Maturity': '4/13/2024',	
            'yas_bond_yld': '4.49%'
          },
          {
            'Maturity': '4/15/2024',	
            'yas_bond_yld': '4.89%'
          },
          {
            'Maturity': '5/3/2024',	
            'yas_bond_yld': '4.82%'
          },
          {
            'Maturity': '5/6/2024',	
            'yas_bond_yld': '4.24%'
          },
          {
            'Maturity': '5/6/2024',	
            'yas_bond_yld': '4.58%'
          },
          {
            'Maturity': '5/12/2024',	
            'yas_bond_yld': '4.62%'
          },
          {
            'Maturity': '5/13/2024',	
            'yas_bond_yld': '4.42%'
          },
          {
            'Maturity': '5/15/2024',	
            'yas_bond_yld': '4.53%'
          },
          {
            'Maturity': '5/17/2024',	
            'yas_bond_yld': '4.68%'
          },
          {
            'Maturity': '6/12/2024',	
            'yas_bond_yld': '4.56%'
          },
          {
            'Maturity': '6/15/2024',	
            'yas_bond_yld': '5.01%'
          },
          {
            'Maturity': '6/28/2024',	
            'yas_bond_yld': '4.85%'
          },
          {
            'Maturity': '7/1/2024',	
            'yas_bond_yld': '4.72%'
          },
          {
            'Maturity': '7/9/2024',	
            'yas_bond_yld': '4.89%'
          },
          {
            'Maturity': '7/16/2024',	
            'yas_bond_yld': '4.73%'
          },
          {
            'Maturity': '7/31/2024',	
            'yas_bond_yld': '4.97%'
          },
          {
            'Maturity': '8/9/2024',	
            'yas_bond_yld': '4.72%'
          },
          {
            'Maturity': '8/26/2024',	
            'yas_bond_yld': '4.90%'
          },
          {
            'Maturity': '9/6/2024',	
            'yas_bond_yld': '4.17%'
          },
          {
            'Maturity': '9/9/2024',	
            'yas_bond_yld': '4.77%'
          },
          {
            'Maturity': '9/10/2024',	
            'yas_bond_yld': '4.75%'
          },
          {
            'Maturity': '9/10/2024',	
            'yas_bond_yld': '4.93%'
          },
          {
            'Maturity': '9/13/2024',	
            'yas_bond_yld': '4.65%'
          },
          {
            'Maturity': '10/7/2024',	
            'yas_bond_yld': '4.62%'
          },
          {
            'Maturity': '10/23/2024',	
            'yas_bond_yld': '4.82%'
          },
          {
            'Maturity': '10/24/2024',	
            'yas_bond_yld': '4.54%'
          },
          {
            'Maturity': '11/1/2024',	
            'yas_bond_yld': '4.69%'
          },
          {
            'Maturity': '11/8/2024',	
            'yas_bond_yld': '4.50%'
          },
          {
            'Maturity': '11/18/2024',	
            'yas_bond_yld': '4.51%'
          },
          {
            'Maturity': '11/29/2024',	
            'yas_bond_yld': '4.98%'
          },
          {
            'Maturity': '12/16/2024',	
            'yas_bond_yld': '4.44%'
          },
          {
            'Maturity': '1/10/2025',	
            'yas_bond_yld': '4.78%'
          },
          {
            'Maturity': '1/10/2025',	
            'yas_bond_yld': '4.78%'
          },
          {
            'Maturity': '1/13/2025',	
            'yas_bond_yld': '4.61%'
          },
          {
            'Maturity': '1/13/2025',	
            'yas_bond_yld': '4.70%'
          },
          {
            'Maturity': '1/21/2025',	
            'yas_bond_yld': '4.60%'
          },
          {
            'Maturity': '1/22/2025',	
            'yas_bond_yld': '4.95%'
          },
          {
            'Maturity': '1/28/2025',	
            'yas_bond_yld': '4.88%'
          },
          {
            'Maturity': '2/3/2025',	
            'yas_bond_yld': '4.84%'
          },
          {
            'Maturity': '2/9/2025',	
            'yas_bond_yld': '4.39%'
          },
          {
            'Maturity': '2/13/2025',	
            'yas_bond_yld': '4.69%'
          },
          {
            'Maturity': '2/15/2025',	
            'yas_bond_yld': '4.82%'
          },
          {
            'Maturity': '2/19/2025',	
            'yas_bond_yld': '4.70%'
          },
          {
            'Maturity': '2/19/2025',	
            'yas_bond_yld': '4.68%'
          },
          {
            'Maturity': '3/15/2025',	
            'yas_bond_yld': '4.85%'
          },
          {
            'Maturity': '3/24/2025',	
            'yas_bond_yld': '4.61%'
          },
          {
            'Maturity': '3/26/2025',	
            'yas_bond_yld': '4.75%'
          },
          {
            'Maturity': '4/1/2025',	
            'yas_bond_yld': '4.70%'
          },
          {
            'Maturity': '4/7/2025',	
            'yas_bond_yld': '4.82%'
          },
          {
            'Maturity': '4/7/2025',	
            'yas_bond_yld': '5.23%'
          },
          {
            'Maturity': '4/11/2025',	
            'yas_bond_yld': '4.91%'
          },
          {
            'Maturity': '4/13/2025',	
            'yas_bond_yld': '4.52%'
          },
          {
            'Maturity': '4/14/2025',	
            'yas_bond_yld': '4.56%'
          },
          {
            'Maturity': '4/21/2025',	
            'yas_bond_yld': '4.73%'
          },
          {
            'Maturity': '4/27/2025',	
            'yas_bond_yld': '4.80%'
          },
          {
            'Maturity': '5/1/2025',	
            'yas_bond_yld': '4.76%'
          },
          {
            'Maturity': '5/11/2025',	
            'yas_bond_yld': '4.42%'
          },
          {
            'Maturity': '5/13/2025',	
            'yas_bond_yld': '4.39%'
          },
          {
            'Maturity': '5/13/2025',	
            'yas_bond_yld': '4.53%'
          },
          {
            'Maturity': '5/15/2025',	
            'yas_bond_yld': '4.37%'
          },
          {
            'Maturity': '5/30/2025',	
            'yas_bond_yld': '4.93%'
          },
          {
            'Maturity': '6/6/2025',	
            'yas_bond_yld': '4.77%'
          },
          {
            'Maturity': '6/6/2025',	
            'yas_bond_yld': '4.55%'
          },
          {
            'Maturity': '6/7/2025',	
            'yas_bond_yld': '4.72%'
          },
          {
            'Maturity': '6/10/2025',	
            'yas_bond_yld': '4.65%'
          },
          {
            'Maturity': '6/10/2025',	
            'yas_bond_yld': '4.72%'
          },
          {
            'Maturity': '6/11/2025',	
            'yas_bond_yld': '4.83%'
          },
          {
            'Maturity': '6/12/2025',	
            'yas_bond_yld': '4.77%'
          },
          {
            'Maturity': '6/30/2025',	
            'yas_bond_yld': '4.62%'
          },
          {
            'Maturity': '7/15/2025',	
            'yas_bond_yld': '4.51%'
          },
          {
            'Maturity': '7/23/2025',	
            'yas_bond_yld': '4.71%'
          },
          {
            'Maturity': '8/1/2025',	
            'yas_bond_yld': '4.57%'
          },
          {
            'Maturity': '8/4/2025',	
            'yas_bond_yld': '4.71%'
          },
          {
            'Maturity': '8/18/2025',	
            'yas_bond_yld': '4.47%'
          },
          {
            'Maturity': '9/11/2025',	
            'yas_bond_yld': '4.71%'
          },
          {
            'Maturity': '9/29/2025',	
            'yas_bond_yld': '4.70%'
          },
          {
            'Maturity': '10/16/2025',	
            'yas_bond_yld': '4.60%'
          },
          {
            'Maturity': '10/21/2025',	
            'yas_bond_yld': '4.81%'
          },
          {
            'Maturity': '10/29/2025',	
            'yas_bond_yld': '4.25%'
          },
          {
            'Maturity': '11/13/2025',	
            'yas_bond_yld': '4.43%'
          },
          {
            'Maturity': '11/16/2025',	
            'yas_bond_yld': '4.34%'
          },
          {
            'Maturity': '12/16/2025',	
            'yas_bond_yld': '5.07%'
          },
          {
            'Maturity': '1/1/2026',	
            'yas_bond_yld': '5.13%'
          },
          {
            'Maturity': '1/6/2026',	
            'yas_bond_yld': '4.66%'
          },
          {
            'Maturity': '1/12/2026',	
            'yas_bond_yld': '4.57%'
          },
          {
            'Maturity': '1/13/2026',	
            'yas_bond_yld': '4.54%'
          },
          {
            'Maturity': '1/20/2026',	
            'yas_bond_yld': '4.55%'
          },
          {
            'Maturity': '1/27/2026',	
            'yas_bond_yld': '4.78%'
          },
          {
            'Maturity': '1/27/2026',	
            'yas_bond_yld': '4.93%'
          },
          {
            'Maturity': '2/13/2026',	
            'yas_bond_yld': '4.50%'
          },
          {
            'Maturity': '3/3/2026',	
            'yas_bond_yld': '4.81%'
          },
          {
            'Maturity': '3/4/2026',	
            'yas_bond_yld': '4.54%'
          },
          {
            'Maturity': '3/9/2026',	
            'yas_bond_yld': '4.77%'
          },
          {
            'Maturity': '3/9/2026',	
            'yas_bond_yld': '4.80%'
          },
          {
            'Maturity': '3/15/2026',	
            'yas_bond_yld': '4.37%'
          },
          {
            'Maturity': '4/15/2026',	
            'yas_bond_yld': '4.21%'
          },
          {
            'Maturity': '4/19/2026',	
            'yas_bond_yld': '4.53%'
          },
          {
            'Maturity': '4/22/2026',	
            'yas_bond_yld': '4.69%'
          },
          {
            'Maturity': '4/23/2026',	
            'yas_bond_yld': '4.24%'
          },
          {
            'Maturity': '4/27/2026',	
            'yas_bond_yld': '4.50%'
          },
          {
            'Maturity': '5/1/2026',	
            'yas_bond_yld': '4.69%'
          },
          {
            'Maturity': '5/10/2026',	
            'yas_bond_yld': '4.45%'
          },
          {
            'Maturity': '5/13/2026',	
            'yas_bond_yld': '4.52%'
          },
          {
            'Maturity': '6/3/2026',	
            'yas_bond_yld': '4.69%'
          },
          {
            'Maturity': '6/3/2026',	
            'yas_bond_yld': '4.48%'
          },
          {
            'Maturity': '6/3/2026',	
            'yas_bond_yld': '4.83%'
          },
          {
            'Maturity': '6/18/2026',	
            'yas_bond_yld': '4.53%'
          },
          {
            'Maturity': '7/14/2026',	
            'yas_bond_yld': '4.96%'
          },
          {
            'Maturity': '7/27/2026',	
            'yas_bond_yld': '4.77%'
          },
          {
            'Maturity': '7/30/2026',	
            'yas_bond_yld': '4.47%'
          },
          {
            'Maturity': '8/3/2026',	
            'yas_bond_yld': '4.73%'
          },
          {
            'Maturity': '8/15/2026',	
            'yas_bond_yld': '4.62%'
          },
          {
            'Maturity': '8/19/2026',	
            'yas_bond_yld': '4.50%'
          },
          {
            'Maturity': '9/8/2026',	
            'yas_bond_yld': '4.95%'
          },
          {
            'Maturity': '9/10/2026',	
            'yas_bond_yld': '4.73%'
          },
          {
            'Maturity': '9/15/2026',	
            'yas_bond_yld': '4.71%'
          },
          {
            'Maturity': '10/19/2026',	
            'yas_bond_yld': '4.86%'
          },
          {
            'Maturity': '10/22/2026',	
            'yas_bond_yld': '4.86%'
          },
          {
            'Maturity': '10/23/2026',	
            'yas_bond_yld': '4.71%'
          },
          {
            'Maturity': '11/20/2026',	
            'yas_bond_yld': '4.74%'
          },
          {
            'Maturity': '12/15/2026',	
            'yas_bond_yld': '4.43%'
          },
          {
            'Maturity': '1/11/2027',	
            'yas_bond_yld': '4.82%'
          },
          {
            'Maturity': '1/20/2027',	
            'yas_bond_yld': '4.55%'
          },
          {
            'Maturity': '2/22/2027',	
            'yas_bond_yld': '4.53%'
          },
          {
            'Maturity': '3/8/2027',	
            'yas_bond_yld': '4.57%'
          },
          {
            'Maturity': '3/8/2027',	
            'yas_bond_yld': '4.36%'
          },
          {
            'Maturity': '3/10/2027',	
            'yas_bond_yld': '4.54%'
          },
          {
            'Maturity': '3/16/2027',	
            'yas_bond_yld': '4.55%'
          },
          {
            'Maturity': '3/22/2027',	
            'yas_bond_yld': '4.45%'
          },
          {
            'Maturity': '3/25/2027',	
            'yas_bond_yld': '4.12%'
          },
          {
            'Maturity': '4/7/2027',	
            'yas_bond_yld': '4.68%'
          },
          {
            'Maturity': '4/23/2027',	
            'yas_bond_yld': '4.89%'
          },
          {
            'Maturity': '5/4/2027',	
            'yas_bond_yld': '4.52%'
          },
          {
            'Maturity': '6/1/2027',	
            'yas_bond_yld': '4.05%'
          },
          {
            'Maturity': '6/8/2027',	
            'yas_bond_yld': '4.58%'
          },
          {
            'Maturity': '6/15/2027',	
            'yas_bond_yld': '4.29%'
          },
          {
            'Maturity': '7/12/2027',	
            'yas_bond_yld': '4.75%'
          },
          {
            'Maturity': '7/22/2027',	
            'yas_bond_yld': '4.60%'
          },
          {
            'Maturity': '7/25/2027',	
            'yas_bond_yld': '4.55%'
          },
          {
            'Maturity': '8/3/2027',
            'yas_bond_yld': '4.56%'
          },            
        ];                

        let startBudget = document.querySelector('.budget-wrapper .slider-budget .knob').getAttribute('normalized-value');        
        
        document.querySelector('.budget-wrapper .value').innerHTML = parseInt(startBudget).toLocaleString("en", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });

        document.querySelector('.return.value .value').innerText = parseInt(startBudget).toLocaleString("en", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        });

        document.querySelectorAll('.calculator .slider-wrapper').forEach(element => {                     
            const knob = element.querySelector('.slider .knob');
            let snapPoints = [];
            let newSnapPoints = [];            
            let maxX = element.querySelector('.range').offsetWidth;
            let clampValue = null;
            let interpValue = null;
            let maxValue = null;
            let minValue = null;   
            let graphVals = null;
            let minEl;
            let maxEl;     
            let ratio; 
            let period;
            let endDate;
            let startDate = new Date();            
            if(element.classList.contains('period-wrapper')){              
                maxValue = 0;                
                minValue = 99999999;                
                data.forEach((dataElement, dataKey) => {
                    endDate = new Date(dataElement.Maturity)
                    var diff =(endDate.getTime() - startDate.getTime()) / 1000;
                    diff /= (60 * 60 * 24 * 7 * 4);
                    period = Math.abs(Math.round(diff));                                        
                    snapPoints[dataKey] = period;
                    
                    if(parseInt(period) > maxValue){
                        maxValue = parseInt(period)
                        maxEl = dataElement;
                    }                    
                    snapPoints.forEach((value, key) => {
                        newSnapPoints[key] = (value * (maxX-element.querySelector('.knob').offsetWidth)) / maxValue;                        
                    });
                });
                minValue = 0;                    
                interpValue = gsap.utils.interpolate(minValue, maxValue);
                clampValue = gsap.utils.clamp(minValue, maxValue);
            }
            else if(element.classList.contains('budget-wrapper')){
                maxValue = parseInt(element.querySelector('.slider').dataset.max);
                minValue = parseInt(element.querySelector('.slider').dataset.min);
                interpValue = gsap.utils.interpolate(minValue, maxValue);
                clampValue = gsap.utils.clamp(minValue, maxValue);
                let interval = 1000;
                let nrIntervals = maxValue / interval;
                for (let i = minValue; i <= maxValue; i = i + interval){
                    snapPoints.push(i);
                }                                
                newSnapPoints[0] = 0;
                snapPoints.forEach((value, key) => {
                    newSnapPoints[key+1] = (value * (maxX-element.querySelector('.knob').offsetWidth)) / maxValue;                    
                });                
            }                        

            let draggable = Draggable.create(knob, {
                force3D: false, 
                maxDuration: 0.3,
                type:"x",
                edgeResistance:.99,
                bounds:".range",
                lockAxis:true,
                inertia:true,
                throwProps: true,
                snap: {
                    x: newSnapPoints
                },
                onDrag : updateRange,
                onThrowUpdate : updateRange,                
            });                                                              

            function updateRange(){              
                let interval = 1;                
                ratio = this.x / this.maxX;
                if(this.target.parentElement.classList.contains('slider-budget')){
                    interval = 1000;
                    this.target.setAttribute('normalized-value', gsap.utils.snap(interval,Math.round(clampValue(interpValue(ratio)))));
                    this.target.parentElement.parentElement.querySelector('.value').innerHTML = gsap.utils.snap(interval,Math.round(clampValue(interpValue(ratio)))).toLocaleString("en", {
                      style: "currency",
                      currency: "USD",
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0
                    });
                    this.target.parentElement.parentElement.querySelector('.sign').innerHTML = '';
                }
                                
                if(this.target.parentElement.classList.contains('slider-period')){                  
                  this.target.parentElement.parentElement.querySelector('.value').innerHTML = gsap.utils.snap(interval,Math.round(clampValue(interpValue(ratio))));
                    
                  if(gsap.utils.snap(interval,Math.round(clampValue(interpValue(ratio)))) == 1){
                      this.target.parentElement.parentElement.querySelector('.sign').innerText = 'Month';
                  }
                  else{
                      this.target.parentElement.parentElement.querySelector('.sign').innerText = 'Months';
                  }
                }
                var value = (this.x-this.minX)/(this.maxX-this.minX)*100;
                this.target.parentElement.querySelector('.range').style.background = 'linear-gradient(to right, #215383 0%, #215383 ' + value + '%, #c4c4c4 ' + value + '%, #c4c4c4 100%)'                
                updateGraph();
            }
            function updateGraph(){
                let budgetValue = document.querySelector('.budget-wrapper .knob').getAttribute('normalized-value');
                let periodValue = document.querySelector('.period-wrapper .value').innerText;
                let yieldValue = 0;
                data.forEach(element => {
                    startDate
                    endDate = new Date(element.Maturity)
                    var diff =(endDate.getTime() - startDate.getTime()) / 1000;
                    diff /= (60 * 60 * 24 * 7 * 4);
                    period = Math.abs(Math.round(diff));                       
                    if(periodValue == period){
                        yieldValue = parseFloat(element.yas_bond_yld);
                    }
                });
                let yieldValuePercentage = yieldValue / 100;                                
                let result = parseFloat(budgetValue)+parseInt(budgetValue)*parseFloat(yieldValuePercentage)*parseInt(periodValue)/12                     

                document.querySelector('.return.value .sign').innerText = '';
                document.querySelector('.return.value .value').innerText = result.toLocaleString("en", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0
                });
                document.querySelector('.return.percent .value').innerText = yieldValue;                                                  
            }

            document.querySelectorAll('.calculator .slider-wrapper .knob').forEach(knob => {
                knob.addEventListener('pointerdown', () => {
                    gsap.to(knob, { scale: 1.2 })
                })
                
                knob.addEventListener('pointerup', () => {
                    gsap.to(knob, { scale: 1 })
                })    
                
                knob.parentElement.addEventListener('mouseleave', () => {
                    gsap.to(knob, { scale: 1 })
                })
            });   
                     
            
        });
    }
	// kill() {
	// 	super.kill();
	// }
}


export default HomeInvesting;