import Highway from '@dogstudio/highway';
import gsap from 'gsap';

// Fade
class Fade extends Highway.Transition {
  in({ from, to, done }) {      
    document.querySelector('body').className = to.getAttribute('class');        
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View and section body_class
    from.remove();
    document.querySelector('main > section').className = '';
    document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
    // Animation
    gsap.fromTo(to, 0.5,
      { opacity: 0 },
      {
        opacity: 1,
        onComplete: done
      }
    );      
  }  
  
  out({ from, done }) {    
    // Animation        
    gsap.fromTo(from, 0.5,
      { opacity: 1 },
      {
        opacity: 0,
        onComplete: done
      }
    );    
  }
  
}

export default Fade;

