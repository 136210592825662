import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';
import Location from '../components/about-us/location';
import Team from '../components/about-us/team';

class AboutUs extends Highway.Renderer{  
  onEnter() {  
    
  }
  onLeave(){

  }
  onEnterCompleted(){ 
    const globals = new Globals();
    const location = new Location();    
    const team = new Team();    
  }
  onLeaveCompleted(){

  }
}
 
export default AboutUs;
 