import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
class Accordeon{
	constructor() {
		this.data = {
            accordeons: document.querySelectorAll('.accordeon-wrapper')
		};

        this.accordeonManagement = this.accordeonManagement.bind(this);

		this.init();
	}
  
	init() {        
        this.accordeonInit(); 
	}

    accordeonInit(){
        const that = this;
        that.data.accordeons.forEach(element => {
            let height = element.querySelector('.accordeon-content-wrapper').getBoundingClientRect().height;
            element.querySelector('.accordeon-content-wrapper').dataset.height = height;
            element.querySelector('.accordeon-content-wrapper').style.height = '0';

            element.querySelector('.title-wrapper').addEventListener('click', that.accordeonManagement);
            
        });
    }

    accordeonManagement() {
        const that = this;        
        if(event.target.classList.contains('open')){
            gsap.to(event.target.parentNode.querySelector('.accordeon-content-wrapper'), {height: '0px', duration: .2, ease: "sine.inOut"});            
            gsap.to(event.target.parentNode.querySelector('.title-wrapper .arrow'), {rotate: '0deg', duration: .2, ease: "sine.inOut", onComplete: () => {
                ScrollTrigger.refresh();	
            }})
            event.target.classList.remove('open');
        }        
        else{
            gsap.to(event.target.parentNode.querySelector('.accordeon-content-wrapper'), {height: event.target.closest('.accordeon-wrapper').querySelector('.accordeon-content-wrapper').dataset.height, duration: .2, ease: "sine.inOut"});

            gsap.to(event.target.querySelector('.title-wrapper .arrow'), {rotate: '-180deg', duration: .2, ease: "sine.inOut", onComplete: () => {
                ScrollTrigger.refresh();	                
            }})

            event.target.classList.add('open')
        }        
    }
}


export default Accordeon;
