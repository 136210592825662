import Swiper, { Navigation } from 'swiper';
Swiper.use([Navigation]);
import 'swiper/css';
import 'swiper/css/navigation';
// import gsap from 'gsap';

class Team{    
	constructor() {
		this.data = {
		};
		this.init();
	}
  
	init() {		                 
        this.initSlider();        
	}	

    initSlider(){
        const swiper = new Swiper('.team .swiper', {
            observer: true,
            observeParents: true,      
            // loop: true,
            // lazy: true,
            speed: 1000,
            fadeEffect: {
                crossFade: true
            },           
            effect: 'fade',             
            navigation: {
				nextEl: '.team .swiper-button-next',
				prevEl: '.team .swiper-button-prev',
			},			  
        });
    }
}


export default Team