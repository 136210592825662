import gsap from 'gsap';

class HomeHow{    
	constructor() {
		this.data = {
            info: document.querySelectorAll('.info')
		};
		this.init();
	}
  
	init() {		
        this.infoPopup();
	}

    infoPopup(){
        const that = this;

        that.data.info.forEach(element => {            
            element.addEventListener('click', this.createPopup);

            document.addEventListener('click', event => {
                const target = event.target.closest('.how-popup .close');
                if(target){
                    gsap.to('.how-popup', {y: -10, autoAlpha: 0, duration:.3, onComplete: () => {
                        event.target.closest('.how-popup').remove();
                        document.body.style.overflow = 'inherit';
                    }});
                }
            })

            // document.addEventListener('mouseenter', event => {                
            //     const target = event.target.closest('.how-popup .close');
            //     if(target){
            //         const spans = target.querySelectorAll('span');
            //         gsap.to(spans, {rotate: 360});
            //     }
            // })
        });
    }

    createPopup(event){                
        const popupEl = document.createElement("div");        
        const content = event.target.parentElement.getAttribute('data-content');        
        popupEl.classList.add('how-popup');        
        const currentDiv = document.querySelector("footer");
        popupEl.innerHTML = "<div class='popup-content'><div class='close'><span></span><span></span></div>"+content+"</div>";
        document.body.append(popupEl);        
        gsap.fromTo(popupEl, {autoAlpha: 0, y: -10}, {autoAlpha:1, y:0, duration:.3, onComplete: () => {
            document.body.style.overflow = 'hidden';
        }});        
    }

}


export default HomeHow;