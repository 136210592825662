import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';

class PressView extends Highway.Renderer{
  onEnter() {  
    
  }
  onLeave(){

  }
  onEnterCompleted(){    
    const globals = new Globals();
  }
  onLeaveCompleted(){

  }
}
 
export default PressView;
   