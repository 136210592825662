import Highway from '@dogstudio/highway';
import ContactsForm from '../components/contacts/form';
import Globals from '../components/global/global';


class ContactsView extends Highway.Renderer{
  onEnter() {    
    
  }
  onLeave(){

  }
  onEnterCompleted(){
    const form = new ContactsForm();
    const globals = new Globals();
  }
  onLeaveCompleted(){

  }
}
 
export default ContactsView;
 