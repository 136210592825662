// import Highway from '@dogstudio/highway';
import gsap from 'gsap';
// import Splide from '@splidejs/splide';

class HomeHero{    
	constructor() {
		this.data = {
            // heroSlider: document.querySelector('.slider'),            
		};
		this.init();
	}
  
	init() {		
        // this.slideInit();        
	}

    // slideInit() {
    //     let that = this;
    //     // const heroSliderMount = new Splide( that.data.heroSlider, {
    //     //     'pagination': false,
    //     //     'arrows': false,
    //     //     'type': 'fade'
    //     // } );
    //     // heroSliderMount.mount();
    // }
	

	// kill() {
	// 	super.kill();
	// }
}


export default HomeHero;