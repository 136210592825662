import Highway from '@dogstudio/highway';
import gsap from 'gsap';
const imagesLoaded = require('imagesloaded');



// SLIDE
class Slide extends Highway.Transition {
  in({ from, to, done }) {      
    document.querySelector('body').className = to.getAttribute('class');
    // Reset Scroll
    window.scrollTo(0, 0);

    // Remove Old View and section body_class
    from.remove();
    document.querySelector('main > div').className = '';
    if(document.querySelector('link[rel="canonical"]'))
      document.querySelector('link[rel="canonical"]').setAttribute('href', window.location.href);
    // Animation    

    gsap.set(document.querySelector('main'), {opacity: 0});

    imagesLoaded( document.querySelector('body'), function( instance ) {            
      gsap.to(document.querySelector('.load-screen'), {opacity: 0, duration:.3, ease:"power4.inOut", onComplete: function(){        
          gsap.set(document.querySelector('.load-screen'), {x:'100vw', opacity: 1});
          gsap.set(document.querySelector('.load-screen .mask'), {x: '0'});
          gsap.to(document.querySelector('main'), {opacity: 1, duration: .1});
          // gsap.set(document.querySelector('.load-screen .logo'), {opacity:0, scale:.1});
          document.body.style.pointerEvents = 'all';          
          done();
      }})
    }); 
  }  
  
  out({ from, done }) {
    // Animation
    document.body.style.pointerEvents = 'none';
    gsap.to(document.querySelector('.load-screen .mask'), {x: '-100vw', duration: 0.5, ease:"power4.inOut"});
    gsap.to(document.querySelector('.load-screen'), {x: 0, duration:0.5, delay: 0.1, ease:"power4.inOut", onComplete: function(){      
      // gsap.to(document.querySelector('.load-screen'), {opacity: 1, x: "100vw", duration:0.5, ease:"power4.out", onComplete: done})
      done();
    }})
  }
  
}

export default Slide;

