import Highway from '@dogstudio/highway';
import { gsap } from 'gsap';
const imagesLoaded = require('imagesloaded');

import Fade from './transitions/fade';
import Slide from './transitions/slide';

import HomeView from './views/home';
import AboutUs from './views/about-us';
import Help from './views/help';
import ContactsView from './views/contacts';
import BlogView from './views/blog';
import SingleBlog from './views/single-blog';
import PricingView from './views/pricing';
import Disclosures from './views/disclosures';
import Search from "./views/search";
import InvestorsView from "./views/investors";
import PressView from "./views/press";
import DefaultView from "./views/default";
import SignUpView from "./views/signup";


/**
 * SASS
 */
import '../sass/app.scss';

/**
 * Highway Core OBJ
 */
const H = new Highway.Core({
    renderers: {
        'home': HomeView,
        'about-us': AboutUs,
        // 'contact': ContactsView,
        'blog': BlogView,
        'single-blog': SingleBlog,
        'categories': BlogView,
        'pricing': PricingView,
        'disclosures': Disclosures,
        'search': Search,
        'help': Help,
        'investors': InvestorsView,
        'press': PressView,
        'default': DefaultView,
        'signup': SignUpView
    },
        transitions: {
        default: Slide,
    }
});

/** UPDATE ALL DOM LINKS FOR HIGHWAY WHEN ADDED TO DOM BY AJAX **/
Object.defineProperty(Highway, 'updateLinks', {
    value: () => {
      const allLinks = document.body.querySelectorAll('a');      
    //   H.detach(allLinks);
      H.attach(allLinks);
    }
});

/**
 * JavaScript
 */
//INITIAL ANIMATION ON REFRESH AND INIT CLASSES SET UP
document.addEventListener("DOMContentLoaded", () => {
    let tl = gsap.timeline(),     
        mySplitText = null,
        chars = null;        

    

    if(document.body.classList.contains('home') || document.body.classList.contains('page-template-page-investors'))
        gsap.set('.hero p, .hero .btns', {opacity: 0});
    
    imagesLoaded( document.querySelector('body'), function( instance ) {
        /*
        shows DOM after all images loaded
        */
        if(document.body.classList.contains('page-template-page-investors')){
            gsap.set(document.querySelector('.load-screen'), {x: '100vw', opacity: 0})            
        }            
        else{
            gsap.set(document.querySelector('.load-screen'), {x: '100vw', opacity: 1})
            gsap.to(document.querySelector('.load-screen'), {opacity: 0, duration: .5, onComplete: () => {
                gsap.set(document.querySelector('.load-screen'), {x:'100vw', opacity: 1});
            }});
        }
        
        if(document.body.classList.contains('home') || document.body.classList.contains('page-template-page-investors')){          
          if(document.querySelector('h1')){
            mySplitText = new SplitText("h1", { type: "words, chars" });
            chars = mySplitText.chars;
          }          
            tl.from(chars, {
                duration: 1,
                opacity: 0,
                scale: 0,
                x: 20,
                delay: 1,
                transformOrigin: "0% 50% -50",
                ease: "lienar",
                stagger: 0.01,
                onComplete: () => {
                    gsap.fromTo('.hero p, .hero .btns', {
                        opacity: 0,
                        y: 10
                    },
                    {
                        opacity: 1,
                        y: 0,
                        stagger: {
                            each: 0.2,
                        }
                    })
                }
            });
        }

        /*
        Remove section body_class
        */
        document.querySelector('main > div').className = '';
    });
});
