import highway from '@dogstudio/highway';
import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
import axios from "axios";
import Accordeon from '../../components/pricing/accordeon';

class MainHelp{
	constructor() {
		this.data = {
            catBtn: document.querySelectorAll('.sidebar .cat-wrapper'),
            mask: document.querySelector('.content .mask'),
            accordeon: new Accordeon()                        
		};
		this.init();
	}
  
	init() {        
        this.getPosts();
        if(document.body.classList.contains('single-help'))
            this.isSingle();
	}

    isSingle() {
        let accordeons = document.querySelectorAll('.pricing-blocks .accordeon-wrapper');        
        accordeons.forEach(element => {            
            if(parseInt(element.dataset.post) == parseInt(document.querySelector('.pricing-blocks').getAttribute('data-current-post'))){                
                element.querySelector('.title-wrapper').click();
                gsap.to(window, {duration: .4, scrollTo:{y: element, offsetY:150}, ease: "power4.inOut"});
            }
        });
    }
    getPosts() {
		const that = this; 
        
		that.data.catBtn.forEach(btn => { 
			btn.addEventListener('click', event => {
                let newUrl = btn.dataset.url;
                window.history.pushState({}, '', newUrl);                                
                if( window.innerWidth <= 1024){
                    gsap.to(window, {duration: .4, scrollTo:{y:"#help-content", offsetY:50}, ease: "power4.inOut"});
                }

                if(document.querySelector('.cat-wrapper.active'))
                    document.querySelector('.cat-wrapper.active').classList.remove('active');
                btn.classList.add('active');

                gsap.to(that.data.mask, {visibility: 'visible', opacity: 1, duration: .2})
                let cat = btn.dataset.cat;                
				axios
					.get("/wp-json/blog/v1/getHelpPosts/?cat="+cat)
					.then((response) => {                     
                        setTimeout(() => {                            
                            document.querySelector('.pricing-blocks').setAttribute('data-current-post', response.data.ID)
                            let catName = event.target.getAttribute('data-cat-name');
                            document.querySelector('.pricing-blocks').innerHTML = '';                                                        
                            if(response.data.length > 0){
                                document.querySelector('.pricing-blocks').innerHTML = '<h2>'+catName+'</h2>';
                            }
                            else{
                                document.querySelector('.pricing-blocks').innerHTML = '<h2>No articles for "'+catName+'"!</h2>';
                            }
                            response.data.forEach(post => {                                                          
                                document.querySelector('.pricing-blocks').innerHTML += `
                                    <div class="accordeon-wrapper" data-post="${post.ID}" id="${post.ID}">  
                                        <div class="title-wrapper">
                                            <div class="content">
                                                <h3>${post.title}</h3>
                                            </div>
                                            <span class="arrow" style="transform: translate(0px, 0px);">
                                                <svg width="14" height="8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.71 7.54 5.65-5.66a1 1 0 0 0-.704-1.71 1 1 0 0 0-.706.29l-5 4.95L2 .46a1 1 0 0 0-1.41 0 1 1 0 0 0-.3.71 1 1 0 0 0 .3.71l5.65 5.66a1 1 0 0 0 1.47 0Z" fill="#2177AB"></path></svg>
                                            </span>
                                        </div>
                                        <div class="accordeon-content-wrapper">
                                        <div>
                                            ${post.content}
                                        </div>
                                        </div>
                                `;
                            });               
                            that.data.accordeon = new Accordeon();                            
                            gsap.to(that.data.mask, {visibility: 'hidden', opacity: 0, duration: .2})                            
                        }, 500);
                        setInterval(() => {
                            highway.updateLinks();
                        }, 1000);
					})		                    
			})
		})        
    }

}
export default MainHelp;

