import gsap from 'gsap';

class BlogSocial{
	constructor() {
		this.data = {
            feed: document.querySelector('.copy-feedback')
		};
		this.init();
	}
  
	init() {			
        const button = document.querySelector('button')
        button.onclick = () => {
            gsap.to(this.data.feed, {opacity:1, visibility: 'visible', duration:1, ease: 'Power4.inOut'})
            navigator.clipboard.writeText(window.location.href);
            setTimeout(() => {
                gsap.to(this.data.feed, {opacity:0, visibility: 'hidden', duration:1, ease: 'Power4.inOut'})
            }, 1000);
        }
	}
}


export default BlogSocial;
