import Highway from '@dogstudio/highway';
import Globals from '../components/global/global';
import axios from "axios";

class SignupView extends Highway.Renderer{
  onEnter() {  
    
  }
  onLeave(){

  }
  onEnterCompleted(){    
    const globals = new Globals();
    document.querySelector('.signup').onsubmit = (e) => {      
      e.preventDefault();
      const form = document.querySelector('.signup');
      const formData = new FormData(form);
      
      axios({
        method: "post",
        url: window.location.protocol + "//" + window.location.hostname + "/wp-admin/admin-ajax.php?action=sendSignupReg",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          console.log(response)
					
          document.querySelector("button[type=submit]").style.opacity = ".5";
          document.querySelector("button[type=submit]").style.pointerEvents = "none";
          if(response.data != 'error'){
            document.querySelector('.response').style.opacity = '1';
            document.querySelector('.response').innerHTML = 'Email Sent';
            // document.querySelector("input[type=submit]").style.opacity = "1";
            // document.querySelector("input[type=submit]").style.pointerEvents = "all";
            // setTimeout(() => {
            //   document.querySelector('.response').style.opacity = '0';
            // }, 5000);
          }
          else{
            document.querySelector('.response').innerHTML = 'Error';
            document.querySelector('.response').style.opacity = '1';
            setTimeout(() => {
              document.querySelector('.response').style.opacity = '0';
            }, 5000);
          }												
        })
        .catch((response) => {
          //handle error
          console.log(response);
          document.querySelector('.response').innerHTML = 'Error';
            document.querySelector('.response').style.opacity = '1';
            setTimeout(() => {
              document.querySelector('.response').style.opacity = '0';
            }, 5000);
        })
    }
  }

    
  onLeaveCompleted(){

  }
}
 
export default SignupView;
   