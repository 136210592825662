// import Highway from '@dogstudio/highway';
import gsap from 'gsap';
// import Draggable from "gsap/Draggable";
// import InertiaPlugin from "../../util/inertiaPlugin"; 
// import VelocityTracker from "../../util/velocityTracker"; 
// import Splide from '@splidejs/splide';
// gsap.registerPlugin(Draggable, InertiaPlugin);

class GlobalApp{    
	constructor() {
		this.data = {
			
		};
		this.init();
	}
  
	init() {		
	}	

	// kill() {
	// 	super.kill();
	// }
}


export default GlobalApp