import Highway from '@dogstudio/highway';
// import { ScrollTrigger } from "gsap/ScrollTrigger";

import Globals from '../components/global/global';

class Search extends Highway.Renderer {
    onEnter() {

    }

    onLeave() {

    }

    onEnterCompleted() {
        const globals = new Globals();
        const element = document.querySelector( 'form' );
        const searchInput = document.getElementById('search');
    }

    onLeaveCompleted() {

    }
}

export default Search;
