import Highway from '@dogstudio/highway';
// import { ScrollTrigger } from "gsap/ScrollTrigger";

import Accordeon from '../components/pricing/accordeon';
import Globals from '../components/global/global';
import GlobalApp from '../components/global/app';

class PricingView extends Highway.Renderer{  
  onEnter() {  
    
  }
  onLeave(){

  }
  onEnterCompleted(){    
    const accordeon = new Accordeon();
    const app = new GlobalApp();
    const globals = new Globals();
  }
  onLeaveCompleted(){

  }
}
 
export default PricingView;
 